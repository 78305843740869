import Header from '../header/Header';
import './hero.sass';

const Hero = () => {
  return (
    <div className='hero-section'>
      <div className='left-mask' />
      <div className='mask' />
      <Header />
      <div className='container hero-texts'>
        <h2 className='main-text'>AYVALIK</h2>
      </div>
    </div>
  );
};

export default Hero;
