import serviceIcon from '../../assets/service-icon.svg';
const ServiceCard = ({ card }) => {
  return (
    <div className='service-card'>
      <img src={serviceIcon} alt='service-icon' />
      <p className='card-title'>{card.title}</p>
      <p className='card-text'>{card.description}</p>
    </div>
  );
};

export default ServiceCard;
