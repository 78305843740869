import ServiceCard from './ServiceCard';
import serviceIcon from '../../assets/service-icon.svg';
import './whyUs.sass';

const WhyUs = () => {
  const services = [
    {
      title: 'Bahçe Düzenlemesi',
      description:
        'Doğayla iç içe yaşamak isteyenler için profesyonel bahçe düzenlemesi hizmetlerimizle, yeşil alanlarınızı estetik ve fonksiyonel bir hale getiriyoruz.',
    },
    {
      title: 'İş Makinesi Kiralama',
      description:
        'İhtiyacınıza uygun iş makinelerini en uygun fiyatlarla kiralama hizmeti sunuyoruz. Projelerinizi hızlandırmak için en iyi ekipmanlarla yanınızdayız.',
    },
    {
      title: 'Kazı İşleri',
      description:
        'Zemin hazırlığı, temel kazıları ve altyapı projelerinde güvenilir kazı hizmetlerimizle, projelerinizi zamanında ve sorunsuz bir şekilde tamamlamanızı sağlıyoruz.',
    },
    {
      title: 'Kanal Temizleme',
      description:
        'Endüstriyel ve ticari tesisler için kanal temizleme hizmetlerimizle, altyapı sistemlerinizin verimliliğini artırıyor ve çevresel riskleri en aza indiriyoruz.',
    },
    {
      title: 'Kanal Açma',
      description:
        'Tıkanıklıklar ve altyapı sorunlarını hızlı ve etkili bir şekilde çözüme kavuşturmak için profesyonel kanal açma hizmetleri sunuyoruz.',
    },
    {
      title: 'Temel Hafriyatı',
      description:
        'İnşaat projelerinizin en önemli aşamalarından biri olan temel hafriyatı konusunda uzman ekiplerimizle, en sağlam temelleri atmanıza yardımcı oluyoruz.',
    },
    {
      title: 'Bahçe Toprağı ve Tesviyesi',
      description:
        'Bahçenizin ihtiyacı olan kaliteli toprağı temin ediyor ve tesviye işlemleriyle ideal bahçe zeminini hazırlıyoruz.',
    },
    {
      title: 'Kum, Çakıl ve Mıcır Temini',
      description:
        'İnşaat projeleriniz için gerekli olan yüksek kaliteli kum, çakıl ve mıcır malzemelerini en uygun fiyatlarla temin ediyoruz.',
    },
    {
      title: 'Yıkım İşlemleri',
      description:
        'Güvenli ve çevreye duyarlı yıkım hizmetlerimizle, eski yapıları etkili bir şekilde ortadan kaldırıyor, yeni projeleriniz için alan açıyoruz.',
    },
    {
      title: 'Kentsel Dönüşüm',
      description:
        'Kentsel dönüşüm projelerinde, güvenilir ve sürdürülebilir çözümlerle, şehirlere yeni bir soluk kazandırıyoruz.',
    },
    {
      title: 'Kuyu Kazımı',
      description:
        'Su ve diğer kaynaklar için ihtiyaç duyduğunuz kuyuları, uzman ekibimizle titizlikle kazıyoruz.',
    },
  ];

  return (
    <section className='why-us-section' id='why-us'>
      <div className='container'>
        <div className='title-container'>
          <h2>NEDEN BİZ ?</h2>
          <p className='subtitle'>HİZMETLERİMİZ</p>
        </div>
        <div className='services-container'>
          {services.map((card) => (
            <ServiceCard card={card} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
