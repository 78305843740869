import './contacts.sass';

const Contacts = () => {
  return (
    <footer id='contact'>
      <h2>İLETİŞİM</h2>
      <div className='contact-content'>
        <div className='phone-container'>
          <p className='title'>Telefon Numarası</p>
          <p>0544 394 63 12</p>
          <p>0507 100 61 63</p>
        </div>
        <div className='mail-container'>
          <p className='title'>E-posta</p>
          <a href='mailto:cetnsnkrt@gmail.com'>cetnsnkrt@gmail.com</a>
        </div>
        <div className='address-info-container'>
          <p className='title'>Adres</p>
          <p>
            BALIKESİR/AYVALIK 150 Evler Mahallesi 29 Mayıs Caddesi No:71 Yeni
            Otogar Yanı
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Contacts;
