import haf1Image from '../../assets/haf_1.jpg';
import haf2Image from '../../assets/haf_2.jpg';
import haf3Image from '../../assets/haf_3.jpg';
import haf4Image from '../../assets/haf_4.jpg';
import haf5Image from '../../assets/haf_5.jpg';
import haf6Image from '../../assets/haf_6.jpg';
import haf7Image from '../../assets/haf_7.jpg';
import haf8Image from '../../assets/haf_8.jpg';
import './slider.sass';
const Slider = () => {
  return (
    <section className='image-slider-section' id='photos'>
      <h2>GALERİ</h2>
      <div className='images'>
        <img src={haf1Image} alt='Hafriyat' />
        <div className='column'>
          <img src={haf2Image} alt='Hafriyat' />
          <div className='sub-column'>
            <img src={haf3Image} alt='Hafriyat' />
            <img src={haf4Image} alt='Hafriyat' />
          </div>
        </div>
        <img src={haf5Image} alt='Hafriyat' />
      </div>
      <div className='images'>
        <img src={haf6Image} alt='Hafriyat' />
        <img src={haf7Image} alt='Hafriyat' />
        <img src={haf8Image} alt='Hafriyat' />
      </div>
    </section>
  );
};

export default Slider;
