import './about.sass';

const About = () => {
  const today = new Date();

  const year2006 = new Date('2006-01-01');

  const differenceInMillis = today - year2006;

  const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;
  const differenceInYears = differenceInMillis / millisecondsPerYear;
  return (
    <section className='about-us-section' id='about-us'>
      <div className='about-us-section__texts'>
        <h2>Hakkımızda</h2>
        <p>
          Ay Genç Hafriyat olarak, Ayvalık'ta 2006 yılından bu yana hafriyat
          sektöründe güvenilir ve profesyonel hizmetler sunmaktayız.
          Kurulduğumuz günden bu yana, müşteri memnuniyetini ve çevreye
          duyarlılığı ön planda tutarak, sektörde öncü bir konuma gelmeyi
          başardık. Uzman ekibimiz ve modern ekipmanlarımızla, her türlü
          hafriyat ve altyapı işinde en yüksek kalitede çözümler sunuyoruz.
          Şirketimiz, projelerinizi zamanında ve en doğru şekilde tamamlayarak,
          beklentilerinizin ötesinde hizmet vermeyi taahhüt eder. Yılların
          getirdiği deneyim ve bilgi birikimi ile projelerinizde en güvenilir iş
          ortağınız olmayı hedefliyoruz.
        </p>
      </div>
      <hr />
      <div className='about-us-section__statistics'>
        <div className='line'>
          <span>100+</span>
          <hr />
          <span>PROJE</span>
        </div>
        <div className='line'>
          <span>{Math.floor(differenceInYears)}</span>
          <hr />
          <span>YIL</span>
        </div>
        <div className='line'>
          <span>10+</span>
          <hr />
          <span>ÇALIŞAN</span>
        </div>
      </div>
    </section>
  );
};

export default About;
