import './header.sass';

const Header = () => {
  const links = [
    {
      href: '#about-us',
      name: 'Hakkımızda',
    },
    {
      href: '#why-us',
      name: 'NEDEN BİZ',
    },
    {
      href: '#photos',
      name: 'GALERİ',
    },
    {
      href: '#contact',
      name: 'İLETİŞİM',
    },
  ];

  const navLinkClicked = () => {
    const toggle = document.querySelector('#menu-toggle');
    toggle.checked = false;
  };

  return (
    <header>
      <div className='container'>
        <a href='#hero' className='logo'>
          AYGENC HAFRİYAT
        </a>
        <input id='menu-toggle' type='checkbox' />
        <label className='menu-button-container' htmlFor='menu-toggle'>
          <div className='menu-button'></div>
        </label>
        <ul className='menu'>
          {links.map((link) => (
            <li onClick={navLinkClicked} key={link.href}>
              <a href={link.href}>{link.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default Header;
