import Hero from './components/hero/Hero';
import About from './components/about/About';
import WhyUs from './components/whyUs/WhyUs';
import Slider from './components/slider/Slider';
import Contacts from './components/contacts/Contacts';
import './App.sass';

function App() {
  return (
    <main className='main-container'>
      <Hero />
      <div className='container'>
        <About />
        <WhyUs />
        <Slider />
        <Contacts />
      </div>
    </main>
  );
}

export default App;
